import axios from 'axios'
import { message } from 'antd';
import qs from 'qs'

const instance = axios.create({
    // baseURL:'http://localhost/HuaTie2/',
    // baseURL: "http://121.41.58.99/HuaTie2/",
    // baseURL: "http://www.yinghuobang.com/HuaTie3/",
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})
// 请求拦截器
instance.interceptors.request.use(function (config) {
    if (config.url === '') {
        config.baseURL = 'https://restapi.amap.com/v3/geocode/geo'
        config.params['key'] = '0dca4d7c52403276cc431a24fc8bd439'
        return config
    }
    if (config.url === 'ip') {
        config.baseURL = 'https://restapi.amap.com/v3/'
        config.params['key'] = '0dca4d7c52403276cc431a24fc8bd439'
        return config
    }
    let id = localStorage.getItem('id')
    // console.log(config);
    if (config.method === 'get' && !config.params['id'] && id) {
        config.params['id'] = id
    } else if (config.method === 'post' && !config.data) {
        // console.log(1);
        config.data = { id }
    } else if (config.method === 'post' && config.data) {
        // console.log(2);
        config.data = Object.assign({ id }, config.data)
    }
    config.data = qs.stringify(config.data)
    return config
}, function (error) {
    return Promise.reject(error)
})

// 响应拦截器
instance.interceptors.response.use(function (response) {
    // console.log(response);
    if (response.data.info) {
        return response.data
    }
    if (response.config.url === "caro/figure" && response.data.code === '999') {
        return Promise.reject(response.data)
    }
    if (response.data.code === '000' || response.data.code === '0') {
        return response.data
    } else {
        message.error(response.data.data);
        return Promise.reject(response.data)
    }
}, function (error) {
    return Promise.reject(error)
})

export default instance



import React, { useEffect, useState } from 'react'
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const Www = () => {
    const navgiate = useNavigate()
    return (
        <Result
            status="404"
            title="404"
            subTitle="很抱歉，没有找到相关页面"
            extra={<Button type="primary" onClick={() => navgiate('/')}>去首页</Button>}
        />
    )
}
export default Www

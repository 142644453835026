import request from '../utils/request'

// 登录
export const login = (params) => request.post('user/login', params)
export const editPsd = (params) => request.post('user/editPsd', params)


// 导航菜单
export const getRoleUrls = (params) => request.post('role/getRoleUrls', params)

// 上传图片
export const upload = (params) => request.post('aliYunOss/upload', params)

// 角色管理
export const roleList = (params) => request.post('role/roleList', params)
// 角色权限
export const getAllRoleList = (params) => request.post('role/getAllRoleList', params)
// 角色管理
export const addRole = (params) => request.post('role/addRole', params)
export const editRole = (params) => request.post('role/editRole', params)
// 发货站点管理
export const userList = (params) => request.post('role/userList', params)
// 发货站点管理状态
export const editUser = (params) => request.post('role/editUser', params)
// 用户角色
export const getRoleList = (params) => request.post('role/getRoleList', params)
// 角色权限编辑
export const saveRoleUrls = (params) => request.post('role/saveRoleUrls', params)

// 发货站点管理重置密码---添加用户
export const saveUser = (params) => request.post('role/saveUser', params)
// 到站点管理
export const logisticsList = (params) => request.post('logistics/logisticsList', params)
// 到站点管理--添加
export const logisticsAdd = (params) => request.post('logistics/logisticsAdd', params)
// 到站点管理--删除
export const logisticsDel = (params) => request.post('logistics/logisticsDel', params)
// 到站点管理--状态
export const logisticsEdit = (params) => request.post('logistics/logisticsEdit', params)
// 司机列表
export const driverList = (params) => request.post('logistics/userList', params)
// 添加司机
export const userAdd = (params) => request.post('logistics/userAdd', params)
// 司机修改
export const userEdit = (params) => request.post('logistics/userEdit', params)
// 用户管理
export const usersList = (params) => request.post('role/usersList', params)
// 用户角色
export const getRoleLists = (params) => request.post('role/getRoleLists', params)
export const saveUsers = (params) => request.post('role/saveUsers', params)
// 用户状态
export const editUsers = (params) => request.post('role/editUsers', params)
// 添加用户下拉选
export const getCategory = (params) => request.post('caro/getCategory', params)
export const getManagyCategory = (params) => request.post('caro/getManagyCategory', params)
// 用户信息
export const getUserInfos = (params) => request.post('role/getUserInfos', params)




// 运单查询
export const caroList = (params) => request.post('caro/caroList', params)
// 运单修改
export const caroEdit = (params) => request.post('caro/caroEdit', params)
// 个人运单查询
export const caroUserList = (params) => request.post('caro/caroUserList', params)
// 项目运单查询
export const caroProjectList = (params) => request.post('caro/caroProjectList', params)
// 运单详情
export const caroDetail = (params) => request.post('caro/caroDetail', params)
// 运单发车
export const caroDrive = (params) => request.post('logistics/caroDrive', params)
// 运单签收
export const sign = (params) => request.post('caro/sign', params)
export const caroSign = (params) => request.post('caro/caroSign', params)
// 运单异常
export const caroAbnormal = (params) => request.post('caro/caroAbnormal', params)
// 导出
export const exportInfo = (params) => request.post('caro/export', params)
// 异常查询
export const caroListAbnormal = (params) => request.post('caro/caroListAbnormal', params)
// 签收管理
export const caroListSign = (params) => request.post('caro/caroListSign', params)
// 签收管理
export const caroSignimgs = (params) => request.post('caro/caroSignimgs', params)
export const caroEditAbnormal = (params) => request.post('caro/caroEditAbnormal', params)
// 制票超级用户 
export const caroAdd = (params) => request.post('caro/caroAdd', params)
// 制票到站点
export const getLogistics = (params) => request.post('caro/getLogistics', params)
// 制票自动输入
export const figure = (params) => request.post('caro/figure', params)
// 中转
export const transitList = (params) => request.post('caro/transitList', params)
export const transitDetail = (params) => request.post('caro/transitDetail', params)

// 申请删除列表
export const caroDelList = (params) => request.post('caro/caroDelList', params)
// 同意删除第1次
export const caroDel = (params) => request.post('caro/caroDel', params)
// 同意删除第二次
export const consentDelete = (params) => request.post('caro/consentDelete', params)
// 拒绝删除
export const refuseDelete = (params) => request.post('caro/refuseDelete', params)
// 中转
export const transitShipment = (params) => request.post('caro/transitShipment', params)
// 发车站点信息
export const shop = (params) => request.post('caro/shop', params)
// 批量发车
export const batchCar = (params) => request.post('caro/batchCar', params)
// 批量发车列表
export const batchCarList = (params) => request.post('caro/batchCarList', params)
// 批量发车详情
export const batchCarDetail = (params) => request.post('caro/batchCarDetail', params)
// 供应商名称搜索
export const applyForEnter = (params) => request.post('paySupplier/applyForEnter', params)
// 托运人信息搜索
export const applyForClient = (params) => request.post('paySupplier/applyForClient', params)
// // 托运人---收货人 填充
// export const figure = (params) => request.post('caro/figure', params)
// 供应商添加信息
export const addCaroInfo1 = (params) => request.post('paySupplier/addCaroInfo1', params)
// 付款申请id
export const integration = (params) => request.post('paySupplier/integration', params)
// 第二次添加信息
export const addCaroInfo2 = (params) => request.post('paySupplier/addCaroInfo2', params)
// 计算总金额
export const calculateMoney = (params) => request.post('paySupplier/calculateMoney', params)
// 销毁整合数据
export const integrationDel = (params) => request.post('paySupplier/integrationDel', params)
// 申请人付款申请列表
export const applicantPaySupplierList = (params) => request.post('paySupplier/applicantPaySupplierList', params)
// 申请人付款申请详情
export const applicantPaySupplierDetail = (params) => request.post('paySupplier/applicantPaySupplierDetail', params)
// 财务下拉选
export const financeList = (params) => request.post('paySupplier/financeList', params)
// 项目经理
export const projectPaySupplierList = (params) => request.post('paySupplier/projectPaySupplierList', params)
// 项目经理同意
export const projectAgree = (params) => request.post('paySupplier/projectAgree', params)
// 项目经理拒绝
export const projectRefuse = (params) => request.post('paySupplier/projectRefuse', params)
// 副总经理
export const assistantPaySupplierList = (params) => request.post('paySupplier/assistantPaySupplierList', params)
export const assistantAgree = (params) => request.post('paySupplier/assistantAgree', params)
export const assistantRefuse = (params) => request.post('paySupplier/assistantRefuse', params)

// 财务
export const financePaySupplierList = (params) => request.post('paySupplier/financePaySupplierList', params)
// 总经理下拉选
export const managerList = (params) => request.post('paySupplier/managerList', params)
// 财务同意
export const financeAgree = (params) => request.post('paySupplier/financeAgree', params)
// 财务拒绝
export const financeRefuse = (params) => request.post('paySupplier/financeRefuse', params)
// 总经理
export const managerPaySupplierList = (params) => request.post('paySupplier/managerPaySupplierList', params)
// 出纳下拉选
export const cashierList = (params) => request.post('paySupplier/cashierList', params)
// 总经理同意
export const managerAgree = (params) => request.post('paySupplier/managerAgree', params)
// 总经理拒绝
export const managerRefuse = (params) => request.post('paySupplier/managerRefuse', params)
// 出纳
export const cashierPaySupplierList = (params) => request.post('paySupplier/cashierPaySupplierList', params)
// 出纳拒绝
export const cashierRefuse = (params) => request.post('paySupplier/cashierRefuse', params)
// 出纳同意
export const cashierAgree = (params) => request.post('paySupplier/cashierAgree', params)

export const applicantPaySupplierAgree = (params) => request.post('paySupplier/applicantPaySupplierAgree', params)
export const applicantPaySupplierRefuse = (params) => request.post('paySupplier/applicantPaySupplierRefuse', params)

export const countDataList = (params) => request.post('/countData/list', params)

export const cargoQuantity = (params) => request.post('/user/cargoQuantity', params)








import { Button, Tabs } from 'antd';
import React, { useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeepAlive, { withAliveScope, useAliveController } from 'react-activation'
const { TabPane } = Tabs;

const Bq = (props) => {
    console.log(props);
    const { collapsed } = props;
    const { drop, dropScope, refresh, clear, getCachingNodes } = useAliveController()
    const navigate = useNavigate() // 跳转
    const location = useLocation();
    const [activeKey, setActiveKey] = useState();
    const [panes, setPanes] = useState([
        {name: '首页', url: '/'}
    ]);
    let isTrue = true
    const onChange = (key) => {
        setActiveKey(key);
    };
    const add = () => {
        setPanes([
            ...panes,
            {
                name: JSON.parse(sessionStorage.getItem('tabs')).name,
                url: JSON.parse(sessionStorage.getItem('tabs')).url,
            },
        ]);
        onChange(JSON.parse(sessionStorage.getItem('tabs')).url)
    };
    // if (sessionStorage.getItem('tabs') && location.pathname != JSON.parse(sessionStorage.getItem('tabs')).url) {
    //     let tab = JSON.parse(sessionStorage.getItem('tabs'))
    //     tab.url = location.pathname
    //     tab.state = location.state
    //     sessionStorage.setItem('tabs', JSON.stringify(tab))
    // }
    if (sessionStorage.getItem('tabs') && JSON.parse(sessionStorage.getItem('tabs')).url != activeKey) {
        panes.map(item => {
            // if (JSON.parse(sessionStorage.getItem('tabs')).hasOwnProperty('name') && item.title.indexOf(JSON.parse(sessionStorage.getItem('tabs')).name) != -1) {
            //     isTrue = false
            // }
            if (JSON.parse(sessionStorage.getItem('tabs')).name === item.name) {
                isTrue = false
            }
            // console.log(item.key);
            // console.log(item.key.indexOf(JSON.parse(sessionStorage.getItem('tabs')).url) != -1);
            // if (item.key.indexOf(JSON.parse(sessionStorage.getItem('tabs')).url) != -1 || JSON.parse(sessionStorage.getItem('tabs')).url.indexOf(item.key) != -1) {
            //     item.key = JSON.parse(sessionStorage.getItem('tabs')).url
            //     item.state = JSON.parse(sessionStorage.getItem('tabs')).state
            // }
        })
        if (isTrue) {
            add()
        } else {
            onChange(JSON.parse(sessionStorage.getItem('tabs')).url)

        }
    }

    const remove = (targetKey) => {
        let index = null
        panes.map((item, i) => {
            if (item.url === targetKey) {
                index = i
                dropScope(item.url)
                // refresh(item.key)
            }
        })
        panes.splice(index, 1);
        console.log(JSON.parse(sessionStorage.getItem("tabs")).url === targetKey);
        let arr = JSON.parse(JSON.stringify(panes))
        setPanes(arr);
        if (JSON.parse(sessionStorage.getItem("tabs")).url === targetKey) {
            console.log(arr);
            if (arr.length == 0) {
                sessionStorage.removeItem('tabs')
                navigate('/')
                return
            }
            onChange(arr[0].url)
            navigate(arr[0].url)
            sessionStorage.setItem('tabs', JSON.stringify({ url: arr[0].url }))
        }
    };

    const onEdit = (targetKey, action) => {

        if (action === 'add') {
            add();
        } else {
            remove(targetKey);
        }
    };
    const onTabClick = (e) => {
        // navigate(e)
        // setActiveKey(e)
        panes.map(item => {
            if (item.url == e) {
                navigate(e)
                setActiveKey(e)
                sessionStorage.setItem('tabs', JSON.stringify(item))
            }
        })
        // sessionStorage.setItem('tabs', JSON.stringify({ url: e }))
    }

    return (
        <div style={{ position: 'fixed', top: '64px', left: collapsed ? '63px' : '213px', right: '30px', zIndex: '99', transition: 'all 0.2s', boxShadow: '-2px 0 5px #ccc', background: '#fff' }}>
            {/* <div
        style={{
          marginBottom: 16,
        }}
      >
        <Button onClick={add}>ADD</Button>
      </div> */}
            <Tabs hideAdd onChange={onChange} activeKey={activeKey} type="editable-card" onEdit={onEdit} onTabClick={onTabClick} >
                {/* <TabPane tab="首页" key="/" closable={false}>
                </TabPane> */}
                {panes.map((item) => (
                    item.url === "/" ?
                    <TabPane tab={item.name} key={item.url} closable={false}>
                    </TabPane>
                    :
                    <TabPane tab={item.name} key={item.url}>
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default Bq;
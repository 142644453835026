import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import MyRouter from './router';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import request from './utils/request'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { AliveScope } from 'react-activation'
moment.locale('en');
React.$axios = request
React.post = request.post
React.get = request.get
ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <AliveScope>
        <MyRouter />
      </AliveScope>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
